export default function ({value, onChange}: {value: any, onChange: any}) {

  const backgroundStyle = {
    background: `linear-gradient(to right, #ff551a 0%, #ff551a ${value / 10}%, hsla(0,0%,100%,.2) ${value / 10}%, hsla(0,0%,100%,.2) 100%)`,
    borderRadius: '8px',
    height: '7px',
    width: 'auto',
    outline: 'none',
    transition: 'background 450ms ease-in',
    WebkitAppearance: 'none' as const, // Specify type explicitly
  };

  return (
    <input
      id="calculator-input"
      type="range"
      min="0"
      max="1000"
      value={value}
      onChange={onChange}
      style={backgroundStyle}
    />
  )
}